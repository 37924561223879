class SerialRequestModel {
    serial_number

    constructor (data) {
        this.serial_number = data.serial_number
    }
}

class SerialResponseModel {
    id
    name
    full_name
    description
    sketches
    img
    category
    manufacturer
    volume
    article_number
    created_at
    updated_at
    reason
    status

    constructor (data) {
        if ('id' in data) {
            this.id = data.id
            this.name = data.name
            this.full_name = data.full_name
            this.description = data.description
            this.sketches = data.sketches
            this.img = data.img
            this.category = data.category
            this.manufacturer = data.manufacturer
            this.volume = data.volume
            this.article_number = data.article_number
            this.created_at = data.created_at
            this.updated_at = data.updated_at
            this.status = 'success'
        } else {
            this.reason = data.reason
            this.status = 'error'
        }

    }
}

export {
    SerialRequestModel,
    SerialResponseModel
}
