import axiosApiInstance from '@/config/axios.config'

import { SerialResponseModel } from '@/models/serial.model'

export default class SerialClient {
    static async checkSerialNumber (data) {
        console.log(data)
        const response = await axiosApiInstance.post('/v1/shop/check/serial_number', data)
        return new SerialResponseModel(response.data)
    }
}
