<template lang="pug">
  .check-code
    Breadcrumb(:current_path="{name: 'CheckCode', title: 'Check Code'}")

    .container.check-code__container
      h1.title_2.check-code__title(v-html="$t('pages.check_code.title')")
      p.check-code__subtitle(v-html="$t('pages.check_code.subtitle')")
      p.check-code__text(v-html="$t('pages.check_code.text')")

      form.check-code__form
        CustomInput(
          :placeholder="$t('form.code')"
          v-model="code"
        ).check-code__form-input
        button(@click.prevent="checkCode" v-html="$t('pages.check_code.button')").check-code__form-button

    .container.check-code__container(v-if="product")
      .col-xs-12.col-sm-12.col-md-8(v-if="product.status === 'success'")
        h1.check-code__product-title.green {{ product.reason }}
      .col-xs-12.col-sm-12.col-md-8(v-else)
        h1.check-code__product-title.red {{ product.reason }}
      .row
        .col-xs-12.col-sm-12.col-md-4
          .check-code__product-image
            BaseImage(:path="product.img" external)

        .col-xs-12.col-sm-12.col-md-8
          h1.check-code__product-title {{ product.name }}
          p.check-code__product-text {{ product.description }}
</template>

<script>
import CustomInput from '@/components/common/CustomInput'
import Breadcrumb from '@/components/common/Breadcrumb'
import BaseImage from '@/components/common/BaseImage'
import SerialService from '@/services/serial.service'

export default {
  name: 'CheckCode',
  components: { Breadcrumb, CustomInput, BaseImage },
  data: () => ({
    code: '',
    product: null
  }),
  methods: {
    async checkCode () {
      await SerialService.checkSerialNumber({ serial_number: this.code })
          .then(response => {
            this.product = response
          })
          .catch(error => {
            this.product = error.response.data
          })
    }
  }
}
</script>
